<template>
  <div v-loading="loading" class="page">
    <div class="main-wrap flex flex-dc">
      <div class="title-wrap">檔案庫</div>
      <div class="content-wrap flex-1 flex">
        <div class="content-left-wrap">
          <div class="content-menu-wrap flex flex-dc">
            <div class="menu-group flex flex-ac" @click="level = 1" :class="{active: level === 1}">
              <i class="icon el-icon-s-order"></i>
              <span class="text">經營層面</span>
            </div>

            <div class="menu-group flex flex-ac" @click="level = 2" :class="{active: level === 2}">
              <i class="icon el-icon-s-order"></i>
              <span class="text">價值層面</span>
            </div>

            <div class="menu-group flex flex-ac" @click="level = 3" :class="{active: level === 3}">
              <i class="icon el-icon-s-order"></i>
              <span class="text">功能層面</span>
            </div>
          </div>
        </div>

        <div class="content-right-wrap flex-1 flex flex-dc">
          <div class="content-title-wrap flex flex-ac flex-jb">
            <div class="title">經營層面</div>
            <div class="tooltip-wrap">
              <el-input placeholder="Search" v-model="search" clearable class="input-with-select">
                <i slot="prepend" class="el-icon-search"></i>
                <div slot="append" class="flex flex-ac">
                  <i class="el-icon-sort pointer mr10"></i>
                  <i class="el-icon-s-operation pointer"></i>
                </div>
              </el-input>
            </div>
          </div>

          <div class="content-list-wrap flex-1 flex">
            <div class="content-list-group flex flex-dc">
              <div class="group-title-wrap flex flex-ac">
                <i class="icon el-icon-circle-plus-outline" @click="newFile(1)"></i>
                <span class="text ml10">Promotion</span>
                <span class="count flex flex-ac flex-jc ml10"> {{ type1File.length }} </span>
              </div>

              <div class="group-list-wrap flex-1 flex-dc" ref="scrollView_1">
                <div v-for="(file, index) in type1File" :key="'file_' + file.id" class="list-item flex flex-dc">
                  <div class="list-item-header flex flex-ac">
                    <span class="mr15">{{ file.code }}</span>
                    <span class="flex flex-1">
                      <a style="width: 100%" @click="downloadFile(file.uri, file.name)">
                        <el-tag class="flex flex-ac flex-jc pointer" effect="plain"
                                style="width: 100%; font-size:calc(0.5vw + 6px)">
                          <img src="@/assets/images/pic-2.png" width="20px" class="mr10" style="vertical-align: middle">
                            {{ file.name }}
                        </el-tag>
                      </a>
                    </span>
                    <el-upload
                        :ref="'avatarUpload_' + index"
                        v-show="cardStatus.group1[`_${index}`].isEdit"
                        :action="uploadUrl"
                        :data="templateUploadData"
                        :headers="uploadHeaders"
                        :on-success="handleUploadFileSuccess"
                        :on-error="handleUploadFileError"
                        :show-file-list="false"
                        :limit="1"
                        :file-list="uploadFileList">
                      <i class="el-icon-upload pointer ml15" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                    </el-upload>

                    <i class="icon el-icon-edit ml5 pointer"
                       :class="{'text-success': cardStatus.group1[`_${index}`].isEdit}"
                       @click="userList = file.creatorInfoList; cardStatus.group1[`_${index}`].isEdit = !cardStatus.group1[`_${index}`].isEdit"></i>
                  </div>

                  <div class="list-item-content flex flex-dc">
                    <div class="form-wrap global-form-wrap">
                      <div class="form-group flex flex-ac">
                        <div class="form-label">標題</div>
                        <div class="form-control flex-1">
                          <el-input v-model="file.name" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus.group1[`_${index}`].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">創作者</div>
                        <div class="form-control flex-1">
                          <el-select v-if="cardStatus.group1[`_${index}`].isEdit" v-model="file.creator" multiple
                                     filterable default-first-option remote :remote-method="searchUser"
                                     placeholder="请选择" size="small" style="width: 100%;">
                            <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"/>
                          </el-select>
                          <span v-else>{{ file.creatorInfoList.map(c => c.nickname).join(', ') }}</span>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">類型</div>
                        <div class="form-control flex-1">
                          <el-select v-model="file.fileType" multiple placeholder="请选择" size="small"
                                     style="width: 100%;" :disabled="!cardStatus.group1[`_${index}`].isEdit">
                            <el-option label="設備類" :value="1"></el-option>
                            <el-option label="經營類" :value="2"></el-option>
                            <el-option label="操作類" :value="3"></el-option>
                            <el-option label="平台類" :value="4"></el-option>
                            <el-option label="看板類" :value="5"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je" v-show="cardStatus.group1[`_${index}`].isEdit">
                        <el-button type="success" size="small" @click="save(file)">Save</el-button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="content-list-group flex flex-dc">
              <div class="group-title-wrap flex flex-ac">
                <i class="icon el-icon-circle-plus-outline" @click="newFile(2)"></i>
                <span class="text ml10">Provide Information</span>
                <span class="count flex flex-ac flex-jc ml10">{{ type2File.length }}</span>
              </div>

              <div class="group-list-wrap flex-1 flex-dc" ref="scrollView_2">
                <div v-for="(file, index) in type2File" :key="'file_' + file.id" class="list-item flex flex-dc">
                  <div class="list-item-header flex flex-ac">
                    <span class="mr15">{{ file.code }}</span>
                    <span class="flex flex-1">
                      <a style="width: 100%" @click="downloadFile(file.uri, file.name)">
                        <el-tag class="flex flex-ac flex-jc pointer" effect="plain"
                                style="width: 100%; font-size:calc(0.5vw + 6px)">
                          <img src="@/assets/images/pic-2.png" width="20px" class="mr10" style="vertical-align: middle">
                            {{ file.name }}
                        </el-tag>
                      </a>
                    </span>
                    <el-upload
                        :ref="'avatarUpload_' + index"
                        v-show="cardStatus.group2[`_${index}`].isEdit"
                        :action="uploadUrl"
                        :data="templateUploadData"
                        :headers="uploadHeaders"
                        :on-success="handleUploadFileSuccess"
                        :on-error="handleUploadFileError"
                        :show-file-list="false"
                        :limit="1"
                        :file-list="uploadFileList">
                      <i class="el-icon-upload pointer ml15" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                    </el-upload>

                    <i class="icon el-icon-edit ml5 pointer"
                       :class="{'text-success': cardStatus.group2[`_${index}`].isEdit}"
                       @click="userList = file.creatorInfoList; cardStatus.group2[`_${index}`].isEdit = !cardStatus.group2[`_${index}`].isEdit"></i>
                  </div>

                  <div class="list-item-content flex flex-dc">
                    <div class="form-wrap global-form-wrap">
                      <div class="form-group flex flex-ac">
                        <div class="form-label">標題</div>
                        <div class="form-control flex-1">
                          <el-input v-model="file.name" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus.group2[`_${index}`].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">創作者</div>
                        <div class="form-control flex-1">
                          <el-select v-if="cardStatus.group2[`_${index}`].isEdit" v-model="file.creator" multiple
                                     filterable default-first-option remote :remote-method="searchUser"
                                     placeholder="请选择" size="small" style="width: 100%;">
                            <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"/>
                          </el-select>
                          <span v-else>{{ file.creatorInfoList.map(c => c.nickname).join(', ') }}</span>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">類型</div>
                        <div class="form-control flex-1">
                          <el-select v-model="file.fileType" multiple placeholder="请选择" size="small"
                                     style="width: 100%;" :disabled="!cardStatus.group2[`_${index}`].isEdit">
                            <el-option label="設備類" :value="1"></el-option>
                            <el-option label="經營類" :value="2"></el-option>
                            <el-option label="操作類" :value="3"></el-option>
                            <el-option label="平台類" :value="4"></el-option>
                            <el-option label="看板類" :value="5"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je" v-show="cardStatus.group2[`_${index}`].isEdit">
                        <el-button type="success" size="small" @click="save(file)">Save</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content-list-group flex flex-dc">
              <div class="group-title-wrap flex flex-ac">
                <i class="icon el-icon-circle-plus-outline" @click="newFile(3)"></i>
                <span class="text ml10">Problem Solution</span>
                <span class="count flex flex-ac flex-jc ml10">{{ type3File.length }}</span>
              </div>

              <div class="group-list-wrap flex-1 flex-dc" ref="scrollView_3">
                <div v-for="(file, index) in type3File" :key="'file_' + file.id" class="list-item flex flex-dc">
                  <div class="list-item-header flex flex-ac">
                    <span class="mr15">{{ file.code }}</span>
                    <span class="flex flex-1">
                      <a style="width: 100%" @click="downloadFile(file.uri, file.name)">
                        <el-tag class="flex flex-ac flex-jc pointer" effect="plain"
                                style="width: 100%; font-size:calc(0.5vw + 6px)">
                          <img src="@/assets/images/pic-2.png" width="20px" class="mr10" style="vertical-align: middle">
                            {{ file.name }}
                        </el-tag>
                      </a>
                    </span>
                    <el-upload
                        :ref="'avatarUpload_' + index"
                        v-show="cardStatus.group3[`_${index}`].isEdit"
                        :action="uploadUrl"
                        :data="templateUploadData"
                        :headers="uploadHeaders"
                        :on-success="handleUploadFileSuccess"
                        :on-error="handleUploadFileError"
                        :show-file-list="false"
                        :limit="1"
                        :file-list="uploadFileList">
                      <i class="el-icon-upload pointer ml15" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                    </el-upload>

                    <i class="icon el-icon-edit ml5 pointer"
                       :class="{'text-success': cardStatus.group3[`_${index}`].isEdit}"
                       @click="userList = file.creatorInfoList; cardStatus.group3[`_${index}`].isEdit = !cardStatus.group3[`_${index}`].isEdit"></i>
                  </div>

                  <div class="list-item-content flex flex-dc">
                    <div class="form-wrap global-form-wrap">
                      <div class="form-group flex flex-ac">
                        <div class="form-label">標題</div>
                        <div class="form-control flex-1">
                          <el-input v-model="file.name" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus.group3[`_${index}`].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">創作者</div>
                        <div class="form-control flex-1">
                          <el-select v-if="cardStatus.group3[`_${index}`].isEdit" v-model="file.creator" multiple
                                     filterable default-first-option remote :remote-method="searchUser"
                                     placeholder="请选择" size="small" style="width: 100%;">
                            <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"/>
                          </el-select>
                          <span v-else>{{ file.creatorInfoList.map(c => c.nickname).join(', ') }}</span>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">類型</div>
                        <div class="form-control flex-1">
                          <el-select v-model="file.fileType" multiple placeholder="请选择" size="small"
                                     style="width: 100%;" :disabled="!cardStatus.group3[`_${index}`].isEdit">
                            <el-option label="設備類" :value="1"></el-option>
                            <el-option label="經營類" :value="2"></el-option>
                            <el-option label="操作類" :value="3"></el-option>
                            <el-option label="平台類" :value="4"></el-option>
                            <el-option label="看板類" :value="5"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je" v-show="cardStatus.group3[`_${index}`].isEdit">
                        <el-button type="success" size="small" @click="save(file)">Save</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content-list-group flex flex-dc">
              <div class="group-title-wrap flex flex-ac">
                <i class="icon el-icon-circle-plus-outline" @click="newFile(4)"></i>
                <span class="text ml10">Power Enhancement</span>
                <span class="count flex flex-ac flex-jc ml10">{{ type4File.length }}</span>
              </div>

              <div class="group-list-wrap flex-1 flex-dc" ref="scrollView_4">
                <div v-for="(file, index) in type4File" :key="'file_' + file.id" class="list-item flex flex-dc">
                  <div class="list-item-header flex flex-ac">
                    <span class="mr15">{{ file.code }}</span>
                    <span class="flex flex-1">
                      <a style="width: 100%" @click="downloadFile(file.uri, file.name)">
                        <el-tag class="flex flex-ac flex-jc pointer" effect="plain"
                                style="width: 100%; font-size:calc(0.5vw + 6px)">
                          <img src="@/assets/images/pic-2.png" width="20px" class="mr10" style="vertical-align: middle">
                            {{ file.name }}
                        </el-tag>
                      </a>
                    </span>
                    <el-upload
                        :ref="'avatarUpload_' + index"
                        v-show="cardStatus.group4[`_${index}`].isEdit"
                        :action="uploadUrl"
                        :data="templateUploadData"
                        :headers="uploadHeaders"
                        :on-success="handleUploadFileSuccess"
                        :on-error="handleUploadFileError"
                        :show-file-list="false"
                        :limit="1"
                        :file-list="uploadFileList">
                      <i class="el-icon-upload pointer ml15" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                    </el-upload>

                    <i class="icon el-icon-edit ml5 pointer"
                       :class="{'text-success': cardStatus.group4[`_${index}`].isEdit}"
                       @click="userList = file.creatorInfoList; cardStatus.group4[`_${index}`].isEdit = !cardStatus.group4[`_${index}`].isEdit"></i>
                  </div>

                  <div class="list-item-content flex flex-dc">
                    <div class="form-wrap global-form-wrap">
                      <div class="form-group flex flex-ac">
                        <div class="form-label">標題</div>
                        <div class="form-control flex-1">
                          <el-input v-model="file.name" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus.group4[`_${index}`].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">創作者</div>
                        <div class="form-control flex-1">
                          <el-select v-if="cardStatus.group4[`_${index}`].isEdit" v-model="file.creator" multiple
                                     filterable default-first-option remote :remote-method="searchUser"
                                     placeholder="请选择" size="small" style="width: 100%;">
                            <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"/>
                          </el-select>
                          <span v-else>{{ file.creatorInfoList.map(c => c.nickname).join(', ') }}</span>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">類型</div>
                        <div class="form-control flex-1">
                          <el-select v-model="file.fileType" multiple placeholder="请选择" size="small"
                                     style="width: 100%;" :disabled="!cardStatus.group4[`_${index}`].isEdit">
                            <el-option label="設備類" :value="1"></el-option>
                            <el-option label="經營類" :value="2"></el-option>
                            <el-option label="操作類" :value="3"></el-option>
                            <el-option label="平台類" :value="4"></el-option>
                            <el-option label="看板類" :value="5"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je" v-show="cardStatus.group4[`_${index}`].isEdit">
                        <el-button type="success" size="small" @click="save(file)">Save</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.page ::v-deep {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f5f5f5;

  .main-wrap {
    height: 100%;
    overflow: hidden;
    color: #555;

    .title-wrap {
      color: $--color-primary;
      font-weight: bold;
      font-size: calc(0.7vw + 7px);
      border-bottom: 1px solid #999;
      padding-bottom: 5px;
      padding: 15px;
    }

    .content-wrap {
      overflow: hidden;

      .content-left-wrap {
        width: calc(7vw + 70px);
        border-right: 1px solid #999;

        .content-menu-wrap {

          .menu-group {
            padding: 10px 20px;
            cursor: pointer;

            &.active {
              background-color: rgba($--color-primary, 0.1);
            }

            .icon {
              font-size: calc(0.8vw + 8px);
              margin-right: 10px;
            }

            .text {
              font-size: calc(0.5vw + 6px);
              font-weight: bold;
            }
          }
        }
      }

      .content-right-wrap {
        .content-title-wrap {
          border-bottom: 1px solid #999;

          .title {
            font-size: calc(0.6vw + 6px);
            font-weight: bold;
            padding-left: 15px;
          }

          .tooltip-wrap {
            .el-input-group__prepend,
            .el-input__inner,
            .el-input-group__append {
              background-color: transparent;
              border: none;

              i {
                font-size: calc(0.7vw + 7px);
              }
            }
          }
        }

        .content-list-wrap {
          overflow: hidden;

          .content-list-group {
            width: 25%;
            border-right: 1px solid #999;
            overflow: hidden;

            .group-title-wrap {
              padding: 15px 10px;

              .icon {
                font-size: calc(0.9vw + 9px);
              }

              .text {
                color: $--color-primary;
                font-weight: bold;
                font-size: calc(0.6vw + 6px);
              }

              .count {
                width: calc(0.8vw + 8px);
                height: calc(0.8vw + 8px);
                background-color: $--color-primary;
                color: #fff;
                border-radius: 100%;
                font-weight: bold;
                font-size: calc(0.5vw + 5px);
              }
            }

            .group-list-wrap {
              overflow-y: auto;
              padding: 0 15px;

              .list-item {
                background-color: #fff;
                box-shadow: 0 5px 5px rgba(#000, 0.2);
                border-radius: 5px;
                margin-bottom: 20px;
                padding: 10px;

                .list-item-header {
                  font-size: calc(0.5vw + 6px);
                  font-weight: bold;
                  border-bottom: 1px solid #999;
                  margin-bottom: 10px;
                  padding-bottom: 10px;

                  .icon {
                    font-size: calc(0.7vw + 7px);
                  }
                }

                .list-item-content {


                  .form-label {
                    width: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import * as fileApi from "@/apis/file";
import * as userApi from "@/apis/user";
import {getToken} from "@/utils/auth";

export default {
  data() {
    return {
      loading: false,
      search: '',
      cardStatus: {
        group1: {
          _1: {
            isEdit: false
          }
        },
        group2: {
          _1: {
            isEdit: false
          }
        },
        group3: {
          _1: {
            isEdit: false
          }
        },
        group4: {
          _1: {
            isEdit: false
          }
        }
      },
      value1: [],
      value2: [],
      level: null,
      fileList: [],
      type1File: [],
      type2File: [],
      type3File: [],
      type4File: [],
      userList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + '/basic/info/upload',
      templateUploadData: {
        typeId: 51
      },
      uploadHeaders: {
        'S-Token': getToken()
      },
      uploadFileList: [],
      uploadFile: null,
    }
  },
  watch: {
    level: function (val) {
      if (val) {
        this.getFileList();
      } else {
        this.fileList = [];
      }
    },
    search: function () {
      this.getFileList();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.level = 1;
    },
    getFileList() {
      this.loading = true;
      fileApi.listByLevel(this.level, this.search).then(data => {
        this.uploadFileList = [];
        this.uploadFile = null;

        this.fileList = data;

        const type1File = this.fileList.filter(item => {
          return item.type === 1
        });
        const cardStatus = {group1: {}, group2: {}, group3: {}, group4: {}};
        for (let i = 0; i <= type1File.length; i++) {
          cardStatus.group1[`_${i}`] = {
            isEdit: false
          }
        }

        const type2File = this.fileList.filter(item => {
          return item.type === 2
        });
        for (let i = 0; i <= type1File.length; i++) {
          cardStatus.group2[`_${i}`] = {
            isEdit: false
          }
        }

        const type3File = this.fileList.filter(item => {
          return item.type === 3
        });
        for (let i = 0; i <= type1File.length; i++) {
          cardStatus.group3[`_${i}`] = {
            isEdit: false
          }
        }

        const type4File = this.fileList.filter(item => {
          return item.type === 4
        });
        for (let i = 0; i <= type1File.length; i++) {
          cardStatus.group4[`_${i}`] = {
            isEdit: false
          }
        }

        this.cardStatus = cardStatus;

        this.type1File = type1File;
        this.type3File = type3File;
        this.type2File = type2File;
        this.type4File = type4File;
      }).finally(() => {
        this.loading = false;
      })
    },
    save(file) {
      this.loading = true;

      if (this.uploadFile) {
        file.uri = this.uploadFile.response.data;
        file.originName = this.uploadFile.name;
        file.size = this.uploadFile.size;
        file.category = this.uploadFile.name.split('.').pop()
      }

      if (!file.id) {
        // 新增
        file.level = this.level;

        fileApi.add(this.level, file).then(data => {
          file.id = data;
          this.getFileList();
        }).finally(() => {
          this.loading = false;
        })
      } else {
        fileApi.update(file).then(() => {
          this.getFileList();
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    /**
     * 搜索用户
     */
    searchUser(value) {
      userApi.list(value).then((res) => {
        this.userList = res;
      })
    },
    newFile(type) {
      this.$set(this.cardStatus[`group${type}`], '_' + this[`type${type}File`].length,
          {
            isEdit: true
          })
      this[`type${type}File`].push({type: type});

      this.$nextTick(() => {
        let scrollElem = this.$refs[`scrollView_${type}`];
        scrollElem.scrollTo({top: scrollElem.scrollHeight, behavior: 'smooth'});
      });
    },
    handleUploadFileSuccess(res, file) {
      if (res.code !== 0) {
        this.$message({
          message: res.msg || '文件上传失败',
          type: 'error'
        });
        return;
      }

      this.uploadFile = file
      this.uploadFileList = [];
    },
    handleUploadFileError() {
      this.uploadFileList = [];
    },
    /**
     * 指定文件名下载
     */
    downloadFile(url, fileName) {
      const x = new window.XMLHttpRequest();
      x.open('GET', url, true);
      x.responseType = 'blob';
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank'
        a.download = fileName;
        a.style.display = 'none'
        document.body.append(a)
        a.click();
      };
      x.send();
    }
  }
}
</script>
